import axios from 'axios'
import { Notify } from 'vant'

const requests = axios.create({
  baseURL: '/api',
  timeout: 50000,
})

requests.interceptors.request.use((config) => {
  const authorization = localStorage.getItem('token')
  if (authorization) {
    config.headers['X-Auth-Token'] = authorization
  }
  return config
})

requests.interceptors.response.use(
  (res) => {
    return res.data
  },
  (err) => {
    if (err.code === "ERR_CANCELED") { return [] }
    if (err && err.response && err.response.status !== 200) {
      console.log(err.response.data.msg||err.response.data.msg, 'err.response.data.msg')
      Notify({
        type: 'danger',
        message: err.response.data.msg||err.response.data.msg||'请求出错，请稍候重试',
      })
      // Message.error(err.response.data.msg)
    }
    // 后端的接口status只有200和500 token过期信息是在500接口里面的response.data中体现的
    // if (err.response.status === 401) {
    if (err.response && err.response.data.code === 401) {
      console.log(err.response.data.msg||err.response.data.msg, 'err.response.data.msg')
      Notify({
        type: 'danger',
        message: err.response.data.msg||err.response.data.msg||'请求出错，请稍候重试',
      })
      setTimeout(() => {
        localStorage.clear()
        window.location.replace('/login')
      })
    }
    return Promise.reject(err)
  }
)

export default requests
