import requests from '../request'
const baseUrl = '/component';
const selectorBaseUrl = '/selector';
const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}
export const getCaptchaCode = () => {
    return requests({
        url: '/auth/captcha',
        method: 'get'
    })

}
export const getOrderTimeLine = (params) => {
    return requests({
        url: baseUrl + '/timeline/order',
        method: 'get',
        params
    })

}
/**
 * 通用上传图片
 * @param file
 */
export function uploadImage(file) {
    return requests(
        {
            method: 'post',
            url: baseUrl + '/upload/image',
            data: file,
        },
        {
            contentType: ContentTypeEnum.BLOB,
        },
    );
}