
import pinyin from 'js-pinyin';
import Moment from 'moment'
import * as math from 'mathjs'
import XEUtils from 'xe-utils'
import { Notify } from 'vant';
/**
 * 文字拼音首字母（大写）
 * @param str
 * @returns {string}
 */
export const getCamelCharsUpperCase = function (str) {
    return pinyin.getCamelChars(str).toUpperCase();
};
export const createError = function (message) {
    Notify({
        type: 'danger',
        message: message,
    });
};
export const cleanedStr = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, '');
};
export const setTodayTimes = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const date = now.getDate();

    const startOfDay = new Date(year, month, date, 0, 0, 0);
    const endOfDay = new Date(year, month, date, 23, 59, 59);

    const todayStart = this.formatDate(startOfDay);
    const todayEnd = this.formatDate(endOfDay);

    // 如果需要显示在界面上，可以将它们赋值给 params
}
export const uuid = () => {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    const uuid = s.join('');
    return uuid;
};
export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
    // const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}`;
    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}



const PATTERN_IS_INTEGER = /^(-?[1-9]\d*|[0])$/;
const PATTERN_IS_INTEGER_GT_ZERO = /^[1-9]\d*$/;
const PATTERN_IS_INTEGER_LT_ZERO = /^-[1-9]\d*$/;
const PATTERN_IS_INTEGER_LE_ZERO = /^(-[1-9]\d*|0)$/;
const PATTERN_IS_INTEGER_GE_ZERO = /^([1-9]\d*|0)$/;
const PATTERN_IS_FLOAT = /^((-?([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0))|((-?[1-9]\d*|0)))$/;
const PATTERN_IS_FLOAT_GT_ZERO = /^(([1-9]\d*\.\d*|0\.\d*[1-9]\d*)|([1-9]\d*))$/;
const PATTERN_IS_FLOAT_LT_ZERO = /^((-([1-9]\d*\.\d*|0\.\d*[1-9]\d*))|(-[1-9]\d*))$/;
const PATTERN_IS_FLOAT_LE_ZERO = /^(((-([1-9]\d*\.\d*|0\.\d*[1-9]\d*))|0?\.0+|0)|(-[1-9]\d*))$/;
const PATTERN_IS_FLOAT_GE_ZERO = /^(([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0)|([1-9]\d*))$/;
const PATTERN_IS_NUMBERIC = /^[0-9]*$/;
const PATTERN_IS_PRICE = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
const PATTERN_IS_CODE = /^[-_.A-Za-z0-9]{1,20}$/;

export function isNull(obj) {
    return XEUtils.isUndefined(obj) || XEUtils.isNull(obj);
}

export function isEmpty(obj) {
    if (isNull(obj)) {
        return true;
    }


    if (XEUtils.isObject(obj)) {
        return XEUtils.isEmpty(obj);
    }

    if (XEUtils.isString(obj)) {
        return obj === '';
    }

    if (XEUtils.isNumber(obj)) {
        return XEUtils.isNaN(obj);
    }

    return false;
}

export function isArray(obj) {
    return XEUtils.isArray(obj);
}

export function isEqualWithStr(obj1 = '', obj2 = '') {
    return XEUtils.isEqual(toString(obj1), toString(obj2));
}

export function toArrayTree(array, options) {
    return XEUtils.toArrayTree(array, options);
}

export function toTreeArray(array, options) {
    return XEUtils.toTreeArray(array, options);
}

export function keys(obj) {
    return XEUtils.keys(obj);
}

export function camelCase(str) {
    return XEUtils.camelCase(str);
}

export function has(obj, property) {
    return XEUtils.has(obj, property);
}

export function get(obj, property, defaultValue) {
    return XEUtils.get(obj, property, defaultValue);
}

export function set(obj, property, value) {
    return XEUtils.set(obj, property, value);
}

export function toString(obj) {
    return XEUtils.toString(obj);
}

export function searchTree(obj, iterate, options, context) {
    return XEUtils.searchTree(obj, iterate, options, context);
}

export function findTree(obj, iterate, options, context) {
    return XEUtils.findTree(obj, iterate, options, context);
}

export function mapTree(obj, iterate, options, context) {
    return XEUtils.mapTree(obj, iterate, options, context);
}

export function isfunction(val) {
    return XEUtils.isfunction(val);
}

export function eachTree(obj, iterate, options, context) {
    XEUtils.eachTree(obj, iterate, options, context);
}

export function union(...array) {
    return XEUtils.union(...array);
}

export function isInteger(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_INTEGER.test(String(value));
}

export function isIntegerGtZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_INTEGER_GT_ZERO.test(String(value));
}

export function isIntegerLtZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_INTEGER_LT_ZERO.test(String(value));
}

export function isIntegerLeZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_INTEGER_LE_ZERO.test(String(value));
}

export function isIntegerGeZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_INTEGER_GE_ZERO.test(String(value));
}

export function isFloat(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_FLOAT.test(String(value));
}

export function isFloatGtZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_FLOAT_GT_ZERO.test(String(value));
}

export function isFloatLtZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_FLOAT_LT_ZERO.test(String(value));
}

export function isFloatLeZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_FLOAT_LE_ZERO.test(String(value));
}

export function isFloatGeZero(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_FLOAT_GE_ZERO.test(String(value));
}

export function isPrice(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_PRICE.test(String(value));
}

export function isCode(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_CODE.test(String(value));
}

export function isNumberic(value) {
    if (isEmpty(value)) {
        return false;
    }

    return PATTERN_IS_NUMBERIC.test(String(value));
}

export function isNumberPrecision(value, precision) {
    if (isEmpty(value)) {
        return false;
    }

    if (!isFloat(value)) {
        return false;
    }

    if (!isIntegerGtZero(precision)) {
        return false;
    }

    let str = String(value);

    if (str.includes('.')) {
        while (str.substring(str.length - 1, str.length) === '0') {
            str = str.substring(0, str.length - 1);
        }

        if (str.substring(str.length - 1, str.length) === '.') {
            return true;
        }

        return str.substring(str.indexOf('.')).length - 1 <= precision;
    }

    return true;
}

export function isPromise(func) {
    return !isNull(func) && XEUtils.isfunction(func.then) && XEUtils.isfunction(func.catch);
}

export function getFullChars(str) {
    return pinyin.getFullChars(str);
}

export function getCamelChars(str) {
    return pinyin.getCamelChars(str);
}

export function getCurrentDate() {
    return Moment().hour(0).minute(0).second(0).millisecond(0);
}

export function getCurrentDateTime() {
    return Moment();
}
export function oneMonthAgoMidnight() {
    return Moment().subtract(1, 'months').startOf('days').format("YYYY-MM-DD HH:mm:ss");;
}

export function formatDateTime(moment, pattern) {
    return moment.format(pattern || 'YYYY-MM-DD HH:mm:ss');
}

export function getDateTimeWithMinTime(moment) {
    return moment.hour(0).minute(0).second(0).millisecond(0);
}

export function getDateTimeWithMaxTime(moment) {
    return moment.hour(23).minute(59).second(59).millisecond(0);
}

export function parseDate(str) {
    return Moment(str, 'YYYY-MM-DD');
}

export function parseDateTime(str) {
    return Moment(str, 'YYYY-MM-DD HH:mm:ss');
}

export function dateTimeToDate(dateTime) {
    return formatDate(Moment(dateTime, 'YYYY-MM-DD HH:mm:ss'));
}


export function md5(v) {
    return CryptoJS.MD5(v);
}

export function readImg(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function getQueryObject(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.indexOf('?') + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1);
        let val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
    });
    return obj;
}

export function add(n1, n2) {
    return math.number(math.add(math.bignumber(n1), math.bignumber(n2)));
}

export function sub(n1, n2) {
    return math.number(math.subtract(math.bignumber(n1||0), math.bignumber(n2||0)));
}

export function mul(n1, n2) {
    return math.number(math.bignumber(n1).mul(math.bignumber(n2)));
}

export function div(n1, n2) {
    return math.number(math.bignumber(n1).div(math.bignumber(n2)));
}

export function gt(n1, n2) {
    return math.bignumber(n1).gt(n2);
}

export function ge(n1, n2) {
    return math.bignumber(n1).gte(n2);
}

export function lt(n1, n2) {
    return math.bignumber(n1).lt(n2);
}

export function le(n1, n2) {
    return math.bignumber(n1).lte(n2);
}

export function eq(n1, n2) {
    return math.bignumber(n1).eq(n2);
}

export function getCountDays() {
    const curDate = new Date();
    const curMonth = curDate.getMonth();
    curDate.setMonth(curMonth + 1);
    curDate.setDate(0);
    return curDate.getDate();
}

