import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import { 
    Step, Steps,Sticky, Dialog, Cell, Form, Button, Popup, Field, CellGroup, RadioGroup, Radio, DropdownItem, DropdownMenu, Row, Col, List, Toast, Search, Icon, Loading, Popover, Picker, DatetimePicker, 
    Uploader} from 'vant'
import moment from 'moment'
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import { VePagination } from "vue-easytable";
import 'vant/lib/index.css';

Vue.use(VePagination);
Vue.use(VueEasytable);
// 1. 引入配置FastClick的js文件
import 'vant/lib/index.css';
import './plugins/fastClick'

// 2. 引入全局样式
import './style/commen.less'
// 3. 引入全局UI组件库
import './plugins/vant'

// 4. 引入rem
import './config/rem'

// 5. 引入全局过滤器
import './config/filters'
// 6. 配置二维码插件
import VueQriously from "vue-qriously/dist/vue-qriously";
// 导入组件
// import './utils/permission.js'
import * as utils from '../src/utils/index';

Vue.prototype.$utils = utils
import Directives from './directive'
Vue.use(Directives)

Vue.use(Uploader);
Vue.use(Step);
Vue.use(Steps);
Vue.use(DatetimePicker)
Vue.use(Popover)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Loading)
Vue.use(Search)
Vue.use(Sticky)
Vue.use(Toast)
Vue.use(List)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Cell);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Button);
Vue.use(Form);
Vue.use(Popup);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(VueQriously)
Vue.use(moment)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
