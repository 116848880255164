export const ADD_GOODS = 'ADD_GOODS';   // 添加商品到购物车
export const INIT_SHOP_CART = 'INIT_SHOP_CART'; // 初始化购物车
export const REDUCE_CART = 'REDUCE_CART'; // 从购物车中移除商品
export const SELECTED_SINGLE_GOODS = 'SELECTED_SINGLE_GOODS'; // 单个商品的选中
export const SELECTED_ALL_GOODS = 'SELECTED_ALL_GOODS'; // 商品的全选
export const CLEAR_CART = 'CLEAR_CART'; // 清空购物车

export const INIT_USER_INFO = 'INIT_USER_INFO'; // 初始化用户数据
export const USER_INFO = 'USER_INFO'; // 保存用户信息
export const RESET_USER_INFO = 'RESET_USER_INFO'; // 重置用户信息
