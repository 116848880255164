function checkPermission(permissionName) {
    // 获取当前登录用户的权限列表（假设已经从服务器获取并存储在了 localStorage 中）
    const userInfo =
        JSON.parse(localStorage.getItem("userInfo")) || [];
    // 判断用户是否拥有指定的权限
    const userPermissions = userInfo.roles || [];
    return userPermissions.indexOf(permissionName) !== -1;
}
export const permission= {

    // 当绑定元素和指令之间的绑定关系建立时立即调用
    bind: (el, binding, vNode) => {
        // 获取需要检查的权限名称
        const permissionName = binding.value;
        // 判断当前用户是否拥有该权限
        if (!checkPermission(permissionName)) {
            // 如果用户没有该权限，则将其隐藏或移除元素
            (el.parentNode && el.parentNode.removeChild(el)) ||
                (el.style.display = "none");
        } else {
            // 如果用户拥有该权限，则显示元素
        }
    },
    unbind: function (el) {
    }
}
export const noPermission= {

    // 当绑定元素和指令之间的绑定关系建立时立即调用
    bind: (el, binding, vNode) => {
        // 获取需要检查的权限名称
        const permissionName = binding.value;
        // 判断当前用户是否拥有该权限
        if (checkPermission(permissionName)) {
            // 如果用户没有该权限，则将其隐藏或移除元素
            (el.parentNode && el.parentNode.removeChild(el)) ||
                (el.style.display = "none");
        } else {
            // 如果用户拥有该权限，则显示元素
        }
    },
    unbind: function (el) {
    }
}